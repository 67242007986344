import { useState } from "react";
import Lightbox from "react-18-image-lightbox";
import * as Icon from 'react-feather';
import * as Unicons from '@iconscout/react-unicons';
import { Link as Link2 } from "react-router-dom";

const fallbackImage = "/images/products/Product-Not-Found.png"; // Replace with the actual path to your 404 image

const ImageSlider = ({ images, BUSINESS_STAGE, SERVICE_TYPES, PROJECT_TYPES }) => {
    const [photoIndex, setActiveIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleCLick = (index) => {
        setActiveIndex(index);
        setOpen(!isOpen);
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <>
            <div className="relative group">
                <div className="overflow-hidden relative transition-all duration-500 ease-in-out">
                    <img
                        src={images[currentIndex]}
                        alt={`Slider ${currentIndex + 1}`}
                        className="w-full h-full object-cover rounded-lg transition-transform duration-500 ease-in-out aspect-[1/1]"
                        loading="lazy"
                        onError={(e) => e.target.src = fallbackImage} // Fallback image if not found
                    />
                    <div className="absolute top-0 left-0 right-0 bottom-0 rounded-lg bg-black bg-opacity-50 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                        <div>
                            <p className="text-white text-xl font-semibold">{SERVICE_TYPES}</p>
                            <p className="text-white text-lg font-semibold">{PROJECT_TYPES}</p>
                        </div>
                    </div>
                </div>
                <div className="absolute bottom-0 left-0 right-0 border-b-2 border-dashed border-amber-500 py-2">
                    <div className="w-full h-auto grid grid-cols-[auto_auto_auto] justify-between items-center p-2">
                        <button
                            className="bg-white text-amber-500 hover:shadow-md hover:shadow-amber-500 rounded-full p-1 opacity-50 hover:opacity-100 transition"
                            onClick={prevImage}
                        >
                            <Icon.ArrowLeft className="rounded-full" />
                        </button>
                        <div className="flex space-x-2">
                            {images.map((_, index) => (
                                <div
                                    key={index}
                                    onClick={() => setCurrentIndex(index)}
                                    className={`w-3 h-3 rounded-full bg-gray-400 ${index === currentIndex ? "bg-blue-500" : ""}`}
                                ></div>
                            ))}
                        </div>
                        <button
                            className="bg-white text-amber-500 hover:shadow-md hover:shadow-amber-500 rounded-full p-1 opacity-50 hover:opacity-100 transition"
                            onClick={nextImage}
                        >
                            <Icon.ArrowRight className="rounded-full" />
                        </button>
                    </div>
                </div>
                <div className="absolute z-10 opacity-0 group-hover:opacity-100 bottom-1/4 -translate-y-1/2 right-2 left-2 text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(currentIndex)} className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16} /></Link2>
                </div>
                <div className="absolute top-0 right-1 mr-2 bg-amber-500 rounded-b-lg px-2 py-1 text-white">
                    {BUSINESS_STAGE}
                </div>
            </div>
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setOpen(false)}
                    onMovePrevRequest={() =>
                        setActiveIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setActiveIndex((photoIndex + 1) % images.length)
                    }
                />
            )}
        </>
    );
};

export default ImageSlider;
