import * as Icon from 'react-feather';
import { BUSINESS_STAGE, PROJECT_TYPES, SERVICE_TOPICS, SERVICE_TYPES, GenerateServiceID, PriceFormated } from './Enums';

export const ServiceTag = {
    STARTUP: 'Startup',
    BUSINESS: 'Small Business',
    PROFESSIONAL: 'Professional',
};

export const expertiseData = [
    {
        title: '1. Planning',
        Icon: Icon.CloudLightning
    },
    {
        title: '2. Feasibility',
        Icon: Icon.Sunrise
    },
    {
        title: '3. Requirements',
        Icon: Icon.StopCircle
    },
    {
        title: '4. Design',
        Icon: Icon.Scissors
    },
    {
        title: '5. Development',
        Icon: Icon.Activity
    },
    {
        title: '6. Testing',
        Icon: Icon.Cast
    },
    {
        title: '7. Deployment',
        Icon: Icon.Droplet
    },
    {
        title: '8. Maintenance',
        Icon: Icon.MapPin
    },
]
export const servicesData = [
    {
        title: 'Graphic Designing',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit',
        Icon: Icon.Compass
    },
    {
        title: 'UX / UI Designing',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit',
        Icon: Icon.Aperture
    },
    {
        title: 'Website Development',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit',
        Icon: Icon.Airplay
    },
    {
        title: 'Andriod App Development',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit',
        Icon: Icon.Smartphone
    },
    {
        title: 'Ios App Development',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit',
        Icon: Icon.Smartphone
    },
    {
        title: 'Marketing',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit',
        Icon: Icon.Map
    },

]
export const project = [
    {
        image: '/images/works/Toolstrain-Mockup.png',
        title: 'Tools Train',
        subTitle: 'Client-Driven UI/UX Design and Development',
    },
    {
        image: '/images/works/MI-ERP.png',
        title: 'MI-ERP',
        subTitle: 'Client-Driven Feature Development',
    },
]
export const blogData = [
    {
        image: "/images/blog/01_giglink.jpg",
        title: 'Giglink: Tailwind CSS NFT Marketplace Template',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image: "/images/blog/01_techwind.jpg",
        title: 'Techwind: Tailwind CSS Multipurpose Template',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image: "/images/blog/01_upwind.jpg",
        title: 'Upwind: Tailwind CSS Landing Page Template',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
]
export const review = [
    {
        name: 'Sarah Johnson',
        designation: 'Marketing Director',
        image: '/images/client/Sarah-Johnson.jpg',
        desc: "Working with this team was seamless! They developed our website with incredible attention to detail, and we couldn’t be happier with the results. Their flexible pricing options made it possible for us to get exactly what we needed without breaking the bank."
    },
    {
        name: 'Mark Thompson',
        designation: 'CEO',
        image: '/images/client/Mark-Thompson.jpg',
        desc: "Their mobile app development exceeded our expectations! Not only was the app ready on time, but it’s also super user-friendly. Their affordable pricing options and commitment to quality set them apart."
    },
    {
        name: 'Emily Richards',
        designation: 'Head of Digital Marketing',
        image: '/images/client/Emily-Richards.jpg',
        desc: "They transformed our digital marketing strategy, and we’ve seen impressive growth in engagement. The team really listens and tailors everything to fit our budget. Highly recommended for any business looking to grow online!"
    },
    {
        name: 'James Walker',
        designation: 'Co-Founder',
        image: '/images/client/James-Walker.jpg',
        desc: "From website design to mobile app features, they delivered everything we needed and more. The flexible pricing allowed us to stay within budget without sacrificing quality. Truly a great experience!"
    },
    {
        name: 'Laura Green',
        designation: 'Brand Manager',
        image: '/images/client/Laura-Green.jpg',
        desc: "Our experience with their digital marketing services has been nothing short of amazing. Affordable pricing, effective strategies, and clear communication – everything a business needs for growth!"
    },
]

// {
//     category: 'Design Services',
//     services: [
//         {
//             title: 'Graphic Designing',
//             desc: 'Creative graphic design services to enhance your brand identity, including logo creation, brochures, and social media graphics.',
//             price: '$150 and up',
//             Icon: Icon.Compass, // Replace with appropriate icon
//             tag: 'Startup',
//         },
//         {
//             title: 'UX / UI Designing',
//             desc: 'Expert UX/UI design to ensure intuitive user experiences and visually appealing interfaces for web and mobile applications.',
//             price: '$300 and up',
//             Icon: Icon.Aperture, // Replace with appropriate icon
//             tag: 'Professional',
//         },
//     ],
// },

// export const servicesDataShop = [
//     {
//         category: 'Website Development',
//         services: [
//             {
//                 title: 'Static Website Development',
//                 desc: 'Include UX/UI, Domain & Hosting, Comprehensive website development services, including responsive designs, e-commerce solutions, and content management systems.',
//                 price: 'up to $500', // (nextjs, .json)landing page & cms platform (smm panel) developed with in 1weak
//                 src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg', // Replace with appropriate icon
//                 tag: ServiceTag.STARTUP,
//             },
//             {
//                 title: 'Website Development',
//                 desc: 'Comprehensive website development services, including responsive designs, e-commerce solutions, and content management systems.',
//                 price: '$500 and up', // (nextjs, mysql)dynamic & headless cms who developed with in 1month
//                 src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg', // Replace with appropriate icon
//                 tag: ServiceTag.BUSINESS,
//             },
//             {
//                 title: 'Website Development last one',
//                 desc: 'Comprehensive website development services, including responsive designs, e-commerce solutions, and content management systems.',
//                 price: '$1000 and up', // (nextjs, mysql)dynamic & headless cms with advance features who developed in 1.5month
//                 src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg', // Replace with appropriate icon
//                 tag: ServiceTag.PROFESSIONAL,
//             },
//         ],
//     },
//     {
//         category: 'App Development',
//         services: [
//             {
//                 title: 'Android App Development',
//                 desc: 'Custom Android application development focused on functionality, performance, and user engagement tailored to your business needs.',
//                 price: '$800 and up',
//                 src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg', // Replace with appropriate icon
//                 tag: ServiceTag.STARTUP,
//             },
//             {
//                 title: 'Android App Development',
//                 desc: 'Custom Android application development focused on functionality, performance, and user engagement tailored to your business needs.',
//                 price: '$800 and up',
//                 src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg', // Replace with appropriate icon
//                 tag: ServiceTag.BUSINESS,
//             },
//             {
//                 title: 'Android App Development',
//                 desc: 'Custom Android application development focused on functionality, performance, and user engagement tailored to your business needs.',
//                 price: '$800 and up',
//                 src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg', // Replace with appropriate icon
//                 tag: ServiceTag.PROFESSIONAL,
//             },
//         ],
//     },
//     {
//         category: 'Marketing Services',
//         services: [
//             {
//                 title: 'Marketing',
//                 desc: 'Strategic marketing services including SEO, content marketing, and social media strategies to enhance your online presence.',
//                 price: '$300 per month',
//                 src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg', // Replace with appropriate icon
//                 tag: 'Business',
//             },
//         ],
//     },
//     {
//         category: 'API Development',
//         services: [
//             {
//                 title: 'Custom API Development',
//                 desc: 'Tailored API solutions to enhance application functionality, or choose our subscription-based existing APIs for your projects.',
//                 price: '$500 for custom development, $100/month for subscription-based APIs',
//                 src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg', // Replace with appropriate icon
//                 tag: 'Startup',
//             },
//         ],
//     },
//     {
//         category: 'Platforms',
//         services: [
//             {
//                 title: 'SMM Panel Website Development',
//                 desc: 'Development of social media marketing panels for efficient campaign management, analytics, and user engagement tools.',
//                 price: '$600 and up',
//                 src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg', // Replace with appropriate icon
//                 tag: 'Business',
//             },
//         ],
//     },
// ];

// {
//     category: 'Sale',
//     services: [
//         {
//             title: 'Website + Andriod + IOS APP',
//             desc: 'Development of social media marketing panels for efficient campaign management, analytics, and user engagement tools.',
//             price: '$600 and up',
//             src: Icon.Code, // Replace with appropriate icon
//             tag: ServiceTag.STARTUP,
//         },
//         {
//             title: 'Website + Andriod + IOS APP',
//             desc: 'Development of social media marketing panels for efficient campaign management, analytics, and user engagement tools.',
//             price: '$600 and up',
//             Icon: Icon.Code, // Replace with appropriate icon
//             tag: ServiceTag.BUSINESS,
//         },
//         {
//             title: 'Website + Andriod + IOS APP',
//             desc: 'Development of social media marketing panels for efficient campaign management, analytics, and user engagement tools.',
//             price: '$600 and up',
//             Icon: Icon.Code, // Replace with appropriate icon
//             tag: ServiceTag.PROFESSIONAL,
//         },
//     ],
// },

// https://chatgpt.com/c/67288e9b-e25c-8009-ac0c-16e2d6ca575c sackragroups@gmail.com
export const servicesDataShop = [
    {
        category: 'Website Development',
        subcategories: [
            {
                title: 'Easy-Development (CMS)',
                services: [
                    {
                        title: 'Tailored WordPress Development',
                        desc: [
                            '(✔) Functional website', 
                            '(✔) Responsive Design',
                            '(✔) Includes 5 Pages',
                            '(✔) Content Upload', 
                            '(✘) Custom UX/UI Design',
                            '(✘) Logo Designing',
                            '(✘) Domain & Hosting',
                            '(✘) Extensive SEO Optimization', 
                            '(✘) Speed Optimization 100%',
                            '(✘) Custom Plugin Development',
                            '(✘) Advanced Features', 
                            '(✔) 1 Month Ongoing Maintenance & Support', 
                        ],
                        price: '$500 and up',
                        src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg',
                        tag: ServiceTag.STARTUP,
                    },
                    {
                        title: 'WordPress for Modern Businesses',
                        desc: [
                            '(✔) Functional website', 
                            '(✔) Responsive Design',
                            '(✔) Includes 10 Pages',
                            '(✔) Content Upload', 
                            '(✘) Custom UX/UI Design',
                            '(✘) Logo Designing',
                            '(✔) Domain & Hosting',
                            '(✘) Extensive SEO Optimization', 
                            '(✔) Speed Optimization 100%', // static site generation
                            '(✘) Custom Plugin Development',
                            '(✔) Advanced Features', 
                            '(✔) 1.5 Month Ongoing Maintenance & Support', 
                        ],
                        price: '$500 and up', 
                        src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg',
                        tag: ServiceTag.BUSINESS,
                    },
                    {
                        title: 'Premium WordPress Development for Professionals',
                        desc: [
                            '(✔) Functional website', 
                            '(✔) Responsive Design',
                            '(✔) Includes 15 Pages',
                            '(✔) Content Upload', 
                            '(✔) Custom UX/UI Design',
                            '(✔) Logo Designing',
                            '(✔) Domain & Hosting',
                            '(✔) Extensive SEO Optimization', 
                            '(✔) Speed Optimization 100%', // static site generation
                            '(✔) Custom Plugin Development',
                            '(✔) Advanced Features', 
                            '(✔) 2 Month Ongoing Maintenance & Support', 
                        ],
                        price: '$500 and up',
                        src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg',
                        tag: ServiceTag.PROFESSIONAL,
                    },
                ],
            },
            {
                title: 'Basic Development (Custom)',
                services: [
                    {
                        title: 'Elevate Your Web Experience with Next.js',
                        desc: [
                            '(✔) Functional website',
                            '(✔) 100% Responsive Design',
                            '(✔) Light Weight', 
                            '(✔) SSR Enabled', 
                            '(✔) Includes 5 Pages',
                            '(✔) Content Upload', 
                            '(✘) Custom UX/UI Design',
                            '(✘) Logo Designing',
                            '(✘) Domain & Hosting',
                            '(✔) Speed Optimization 100%', 
                            '(✘) 4 Advance Feature Development', 
                            '(✔) 1.5 Month Ongoing Maintenance & Support', 
                        ],
                        price: '$1000 and up',
                        src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg',
                        tag: ServiceTag.STARTUP,
                    },
                    {
                        // title: 'Technology: Next.js, TailwindCSS, Premium Icons',
                        title: 'Fast, Scalable Websites with Next.js',
                        desc: [
                            '(✔) Functional website',
                            '(✔) 100% Responsive Design',
                            '(✔) Light Weight', 
                            '(✔) SSR Enabled', 
                            '(✔) Includes 10 Pages',
                            '(✔) Content Upload', 
                            '(✘) Custom UX/UI Design',
                            '(✘) Logo Designing',
                            '(✔) Domain & Hosting',
                            '(✔) Speed Optimization 100%', 
                            '(✔) 4 Advance Feature Development', 
                            '(✔) 2 Month Ongoing Maintenance & Support', 
                        ],
                        price: '$1000 and up',
                        src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg',
                        tag: ServiceTag.BUSINESS,
                    },
                    {
                        title: 'High-Performance Websites in Next.js',
                        desc: [
                            '(✔) Functional website',
                            '(✔) 100% Responsive Design',
                            '(✔) Light Weight', 
                            '(✔) SSR Enabled', 
                            '(✔) Includes 10 Pages',
                            '(✔) Content Upload', 
                            '(✔) Custom UX/UI Design',
                            '(✔) Logo Designing',
                            '(✔) Domain & Hosting',
                            '(✔) Speed Optimization 100%', 
                            '(✔) 6 Advance Feature Development', 
                            '(✔) 2.5 Month Ongoing Maintenance & Support', 
                        ],
                        price: '$1000 and up',
                        src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg',
                        tag: ServiceTag.PROFESSIONAL,
                    },
                ],
            },
            {
                title: 'Advance Development (Custom)',
                services: [
                    {
                        title: 'Platform Custom Website Development',
                        desc: [
                            'Feature-rich websites with advanced functionalities',
                            'Custom integrations for seamless operations',
                            'Highly scalable and customizable for business growth'
                        ],
                        price: '$2000 and up',
                        src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg',
                        tag: ServiceTag.STARTUP,
                    },
                    {
                        title: 'Headless CMS Websites',
                        desc: [
                            'API-driven architecture for flexible frontend',
                            'Efficient management of content and media',
                            'Supports multiple platforms for broad reach'
                        ],
                        price: '$2000 and up',
                        src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg',
                        tag: ServiceTag.BUSINESS,
                    },
                    {
                        title: '100% Custom Website Development',
                        desc: [
                            'Tailored solutions for unique business requirements',
                            'Advanced features and highly interactive UI/UX',
                            'Fully optimized for scalability and performance'
                        ],
                        price: '$2000 and up',
                        src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg',
                        tag: ServiceTag.PROFESSIONAL,
                    },
                ],
            },
        ],
    },
    {
        category: 'API Development',
        services: [
            {
                title: 'Custom API Development',
                desc: [
                    'Tailored API solutions to meet specific integration needs',
                    'Highly secure and optimized for fast responses',
                    'Developed to support both mobile and web applications'
                ],
                price: '$1500 and up',
                src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg',
                tag: ServiceTag.PROFESSIONAL,
            },
            {
                title: 'API Integration Services',
                desc: [
                    'Integrate existing APIs into your applications',
                    'Enhance functionality and user experience',
                    'Seamless integration for streamlined operations'
                ],
                price: '$1000 and up',
                src: 'https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg',
                tag: ServiceTag.BUSINESS,
            },
        ],
    },
];


export const servicesData1 = [
    {
        SERVICE_TYPES: SERVICE_TYPES.SAAS,
        PROJECT_TYPES: PROJECT_TYPES.SERVICE_BASED_WEBSITE,
        SERVICE_TOPICS: SERVICE_TOPICS.SMM_PANEL,
        BUSINESS_STAGE: [
            {
                ID: GenerateServiceID(SERVICE_TYPES.SAAS, PROJECT_TYPES.SERVICE_BASED_WEBSITE, SERVICE_TOPICS.SMM_PANEL, BUSINESS_STAGE.STARTUPS),
                name: BUSINESS_STAGE.STARTUPS,
                imgsSlider: ["images/products/smm-panel SG-SSS-1_1.png", "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSj0y6Vhjobxd6Bkhwc5tQ8qHR4yXifC_oovQ&s", "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ_2oII-AssPFNOvcLQ6ecJ6ZWQlUbKU3j8w&s"],
                points: [
                    { point: "Functional website", included: true },
                    { point: "100% Responsive Design", included: true },
                    { point: "Light Weight", included: true },
                    { point: "SSR Enabled", included: true },
                    { point: "Includes 5 Pages", included: true },
                    { point: "Content Upload", included: true },
                    { point: "Custom UX/UI Design", included: false },
                    { point: "Logo Designing", included: false },
                    { point: "Domain & Hosting", included: false },
                    { point: "Speed Optimization 100%", included: true },
                    { point: "4 Advance Feature Development", included: false },
                    { point: "1.5 Month Ongoing Maintenance & Support", included: true },
                ],
                price: "UP TO "+PriceFormated(340),
            },
            {
                ID: GenerateServiceID(SERVICE_TYPES.SAAS, PROJECT_TYPES.SERVICE_BASED_WEBSITE, SERVICE_TOPICS.SMM_PANEL, BUSINESS_STAGE.SMALL_BUSINESSES),
                name: BUSINESS_STAGE.SMALL_BUSINESSES,
                imgsSlider: ["https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg", "https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg", "https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg"],
                points: [
                    { point: "Functional website", included: true },
                    { point: "100% Responsive Design", included: true },
                    { point: "Light Weight", included: true },
                    { point: "SSR Enabled", included: true },
                    { point: "Includes 5 Pages", included: true },
                    { point: "Content Upload", included: true },
                    { point: "Custom UX/UI Design", included: false },
                    { point: "Logo Designing", included: false },
                    { point: "Domain & Hosting", included: true },
                    { point: "Speed Optimization 100%", included: true },
                    { point: "4 Advance Feature Development", included: true },
                    { point: "2 Month Ongoing Maintenance & Support", included: true },
                ],
                price: PriceFormated(400)+" to up",
            },
            {
                ID: GenerateServiceID(SERVICE_TYPES.SAAS, PROJECT_TYPES.SERVICE_BASED_WEBSITE, SERVICE_TOPICS.SMM_PANEL, BUSINESS_STAGE.ESTABLISHED_ENTERPRISES),
                name: BUSINESS_STAGE.ESTABLISHED_ENTERPRISES,
                imgsSlider: ["https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg", "https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg", "https://itartificer.com/wp-content/uploads/2019/01/website-development.jpg"],
                points: [
                    { point: "Functional website", included: true },
                    { point: "100% Responsive Design", included: true },
                    { point: "Light Weight", included: true },
                    { point: "SSR Enabled", included: true },
                    { point: "Includes 5 Pages", included: true },
                    { point: "Content Upload", included: true },
                    { point: "Custom UX/UI Design", included: true },
                    { point: "Logo Designing", included: true },
                    { point: "Domain & Hosting", included: true },
                    { point: "Speed Optimization 100%", included: true },
                    { point: "6 Advance Feature Development", included: true },
                    { point: "2.5 Month Ongoing Maintenance & Support", included: true },
                ],
                price:  PriceFormated(510)+" to up",
            }
        ]
    }, 
]
