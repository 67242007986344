// import axios from 'axios';

// const apiBaseUrl = "https://apisackragroupscom.vercel.app/api"; // Update if needed
// const axiosClient = axios.create({
//     baseURL: apiBaseUrl,
//     headers: {
//         "Content-Type": "application/json",
//         "x-api-key": process.env.REACT_APP_EMAIL_API_KEY,
//     }
// });

// export const sendEmail = (emailData) => axiosClient.post("/send-mail", {
//     subject: emailData.subject,
//     text: `From: ${emailData.name} <${emailData.email}>\n\nMessage:\n${emailData.comments}`
// });


// import axios from 'axios';

// const apiBaseUrl = "https://apisackragroupscom.vercel.app/api/send-mail";

// export const sendEmail = async (formData) => {
//     console.log(formData);
//     const emailData = {
//         to: "info@sackragroups.com",
//         subject: "Test Email from Sackra Groups",
//         text: "Hello! Usama Siddique This is a test email sent using Zoho Mail via Next.js."
//     };

//     try {
//         const response = await axios.post(apiBaseUrl, emailData, {
//             headers: {
//                 "Content-Type": "application/json",
//                 "x-api-key": "Yg,lO&Lj|Jz|:N[BRU[]ZUyv[xu%4):+5"
//             }
//         });

//         console.log("Email sent successfully:", response.data);
//     } catch (error) {
//         console.error("Error sending email:", error.response?.data || error.message);
//     }
// };


export const sendEmail = async (formData) => {
    const url = "https://apisackragroupscom.vercel.app/api/send-mail";
    // const apiKey = "Yg,lO&Lj|Jz|:N[BRU[]ZUyv[xu%4):+5";
  
    const emailData = {
      to: "info@sackragroups.com",
      subject: formData.subject,
      text: `From: ${formData.name} \nMail: ${formData.email}\nMessage:\n${formData.comments}`
    };
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_EMAIL_API_KEY || process.env.NEXT_PUBLIC_EMAIL_API_KEY,
        },
        body: JSON.stringify(emailData),
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log("Email sent successfully:", result);
      } else {
        console.error("Failed to send email:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  