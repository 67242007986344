import React from "react";
import CountUp from 'react-countup';
import { servicesDataShop } from "../Data/data";
import { TypeAnimation } from "react-type-animation";

export default function AboutUsShop() {

    const categories = servicesDataShop.map(category => category.category);
    const animationSequence = categories.flatMap(category => [' ' + category + ' ', 1000]);

    // Extract unique category names
    return (
        <section className="relative md:py-24 py-16 customAboutUsShopMargin" id="about">
            <div className="container">
                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                    <div className="lg:col-span-5 lg:px-8">
                        <div className="relative">
                            <div className="absolute inset-0 border dark:border-gray-800 rounded-full -mt-[10px] -ms-3 h-[100%] w-[100%] -z-1"></div>
                            <img src="/images/hero/Brand Embesider 2.png" className="rounded-full shadow-md shadow-gray-200 dark:shadow-gray-800" alt="Brand Ambassador" />

                            <div className="absolute lg:bottom-20 md:bottom-10 bottom-6 ltr:lg:-right-16 rtl:lg:-left-16 ltr:md:-right-8 rtl:md:-left-8 ltr:right-0 rtl:left-0 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 m-3 w-44 text-center">
                                <h6 className="font-semibold">Web Desinger</h6>
                                <span className="text-2xl font-medium text-amber-500 mb-0"><span className="counter-value font-bold" data-target="7"><CountUp
                                    start={7}
                                    className="counter-value"
                                    end={12}
                                    duration={2.75} /></span>+</span>
                                <span className="text-sm text-slate-400">Years <br /> Experience</span>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-7">
                        <div className="lg:ms-5">
                            <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Discover
                                <TypeAnimation
                                    sequence={animationSequence}
                                    wrapper="span"
                                    speed={50}
                                    className="typewrite text-amber-500"
                                    repeat={Infinity}
                                /> Service </h3>

                            <p className="text-slate-400 max-w-xl text-[15px]">Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development cycle for dynamic web projects. The as opposed to using 'Content here, content here', making it look like readable English.</p>
                            <p className="text-slate-400 max-w-xl text-[15px] mt-6">I'm a professional web designer. My motive is to build a best web design with my all years of experience.</p>

                            <div className="mt-6">
                                <a href="#project" className="btn bg-amber-500/10 hover:bg-amber-500 border-amber-500/10 hover:border-amber-500 text-amber-500 hover:text-white rounded-md me-2 mt-2">Hire Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}