import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/css/tailwind.css';
import './assets/css/materialdesignicons.min.css'
import Index from './main/index';
import Switcher from './components/Switcher';
import IndexTwo from './main/index-two';
import IndexThree from './main/index-three';
import IndexDark from './main/index-dark';
import IndexRtl from './main/index-rtl';
import NotFound from './main/notfound';


function App() {
  const handleClick = () => {
    const phoneNumber = "+923020008853"; 
    const message = "Hello! How can i help you?"; 
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };
  return (
    <BrowserRouter >
    <Routes>
      <Route path="/" element={<Index handleClick={handleClick} />} />
      <Route path="/services" element={<IndexTwo handleClick={handleClick}/>} />
      <Route path="/sale" element={<IndexTwo handleClick={handleClick}/>} />
      <Route path="*" element={<NotFound/>} />
      <Route path="/index-three" element={<IndexThree/>} />
      <Route path="/index-dark" element={<IndexDark/>} />
      <Route path="/index-rtl" element={<IndexRtl/>} />
    </Routes>
    <Switcher/>
  </BrowserRouter>
  );
}

export default App;
