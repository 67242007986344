import React, { useState } from "react";
// import { servicesDataShop } from "../Data/data"
import { servicesData1 } from "../Data/data"
import { Link as Link1 } from 'react-scroll';
import ImageSlider from "./ImageSlider";
import { SERVICE_TYPES, PROJECT_TYPES, SERVICE_TOPICS, BUSINESS_STAGE } from "../Data/Enums";
import Select from 'react-select';

export default function ServiceShop({ handleClick }) {
    const [selectedServiceType, setSelectedServiceType] = useState(null);
    const [selectedProjectType, setSelectedProjectType] = useState(null);
    const [selectedServiceTopic, setSelectedServiceTopic] = useState(null);
    const [selectedBusinessStage, setSelectedBusinessStage] = useState(null);

    // KEY AND VALUE MAPPING NEED TO MAKE PROPER FUNCTIONALITY
    const serviceTypeOptions = Object.keys(SERVICE_TYPES).map(key => ({ value: SERVICE_TYPES[key], label: key }));
    const projectTypeOptions = Object.keys(PROJECT_TYPES).map(key => ({ value: PROJECT_TYPES[key], label: key }));
    const serviceTopicOptions = Object.keys(SERVICE_TOPICS).map(key => ({ value: SERVICE_TOPICS[key], label: key }));
    const businessStageOptions = Object.keys(BUSINESS_STAGE).map(key => ({ value: BUSINESS_STAGE[key], label: key }));
    console.log(serviceTypeOptions);

    const filteredServices = servicesData1.filter(service =>
        (!selectedServiceType || service.SERVICE_TYPES === selectedServiceType.value) &&
        (!selectedProjectType || service.PROJECT_TYPES === selectedProjectType.value) &&
        (!selectedServiceTopic || service.SERVICE_TOPICS === selectedServiceTopic.value) &&
        (!selectedBusinessStage || service.BUSINESS_STAGE.some(stage => stage.name === selectedBusinessStage.value)
        )
    );
    // If a business stage is selected, filter services so they only include that specific stage.
    const filteredBusinessStageServices = selectedBusinessStage
        ? filteredServices.map(service => ({
            ...service,
            BUSINESS_STAGE: service.BUSINESS_STAGE.filter(
                stage => stage.name === selectedBusinessStage.value
            ),
        }))
        : filteredServices;

    console.log(filteredBusinessStageServices);

    return (
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="service">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-2 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">What do we offer?</h3>
                    <p className="text-slate-400 max-w-xl mx-auto text-[15px]"><span className="font-bold text-amber-500">Note: </span> if you have custom requirements then please <Link1 to="contact" className="nav-link border-b-[1px] border-amber-500 cursor-pointer hover:border-b-2" duration={500}>Contact Us</Link1> </p>
                </div>
                {/* Filter Section */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
                    <Select
                        options={serviceTypeOptions}
                        placeholder="Select Service Type"
                        isClearable
                        onChange={setSelectedServiceType}
                        value={selectedServiceType}
                    />
                    <Select
                        options={projectTypeOptions}
                        placeholder="Select Project Type"
                        isClearable
                        onChange={setSelectedProjectType}
                        value={selectedProjectType}
                    />
                    <Select
                        options={serviceTopicOptions}
                        placeholder="Select Service Topic"
                        isClearable
                        onChange={setSelectedServiceTopic}
                        value={selectedServiceTopic}
                    />
                    <Select
                        options={businessStageOptions}
                        placeholder="Select Business Stage"
                        isClearable
                        onChange={setSelectedBusinessStage}
                        value={selectedBusinessStage}
                    />
                </div>
                
                {/* Services Display */}
                <div id="Services">
                    {filteredBusinessStageServices.length > 0 ? (
                        filteredBusinessStageServices.map((service, index) => (
                            <div key={index}>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                                    {service.BUSINESS_STAGE.map((stage, index) => (
                                        <div key={index} id={stage.ID} className="bg-white rounded-lg">
                                            <ImageSlider images={stage.imgsSlider} BUSINESS_STAGE={stage.name} SERVICE_TYPES={service.SERVICE_TYPES} PROJECT_TYPES={service.PROJECT_TYPES} />
                                            <div className="body">
                                                <h3 className="text-lg font-semibold text-gray-800 mb-2 border-b-2 border-dashed border-amber-500 px-4 py-2">{service.SERVICE_TOPICS}</h3>
                                                {stage.points.map((point, index) => (
                                                    <p key={index} className={`px-4 py-1 ${point.included ? '' : 'text-disable'}`}>{point.included ? '(✔)' : '(✘)'}{" "}{point.point}</p>
                                                ))}
                                                <p className="text-center py-3">{stage.price.toUpperCase()}</p>
                                                <button className="bg-amber-500 p-3 w-full rounded-sm text-white border-2 border-transparent hover:underline hover:bg-white hover:text-black hover:border-amber-500 hover:border-2" onClick={handleClick}>Buy Now</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))
                    ) : (
                        <h3 className="mb-2 md:text-xl text-lg text-amber-500 text-center md:leading-normal leading-normal font-semibold">No Services Found. Please <Link1 to="contact" className="nav-link border-b-[1px] border-amber-500 cursor-pointer hover:border-b-2" duration={500}>Contact Us</Link1> </h3>
                    )}
                </div>
            </div>
        </section>
    )
}