export const CURRENCIES = Object.freeze({
    USD: {
        NAME: 'USD',
        SYMBOL: '$',
        DECIMAL_SEPARATOR: '.',
        THOUSANDS_SEPARATOR: ',',
        CURRENCY_CODE: 'USD',
        LOCALE: 'en-US'  // Locale specific to this currency
    },
    EUR: {
        NAME: 'EUR',
        SYMBOL: '€',
        DECIMAL_SEPARATOR: ',',
        THOUSANDS_SEPARATOR: '.',
        CURRENCY_CODE: 'EUR',
        LOCALE: 'de-DE'  // Locale specific to EUR
    },
});
export const DEFAULTCURRENCY = CURRENCIES.USD;



export const SERVICE_TYPES = Object.freeze({
    SAAS: "Software as a Service (SaaS)",
    WEBSITE_DEVELOPMENT: "Website Development",
    ANDROID_APP_DEVELOPMENT: "Android App Development",
    IOS_APP_DEVELOPMENT: "iOS App Development",
    API_DEVELOPMENT: "API Development",
    MAINTENANCE_SUPPORT: "Maintenance & Support",
    DIGITAL_MARKETING: "Digital Marketing",
});

export const BUSINESS_STAGE = Object.freeze({
    STARTUPS: "Startups",
    SMALL_BUSINESSES: "Small Businesses",
    ESTABLISHED_ENTERPRISES: "Established Enterprises",
});

export const PROJECT_TYPES = Object.freeze({
    ECOMMERCE_STORE: "E-commerce Store",
    PORTFOLIO_WEBSITE: "Portfolio Website",
    MEMBERSHIP_PLATFORM: "Membership Platform",
    BLOG: "Blog",
    SCHEDULING_SYSTEM: "Scheduling System",
    ONEPAGE_WEBSITE: "One-Page Website",
    ONLINE_COURSE_PLATFORM: "Online Course Platform",
    SERVICE_BASED_WEBSITE: "Service-Based Website",
    DONATION_PLATFORM: "Donation Platform",
    CUSTOM_SOLUTIONS: "Custom Solutions",
    OTHER: "Other (Please Specify)",
});

export const SERVICE_TOPICS = Object.freeze({
    SMM_PANEL: "Social Media Marketing (SMM) Panel",
    ECOMMERCE_WEBSITES: "E-commerce Websites",
    ENTERPRISE_WEBSITES: "Enterprise Websites",
    DIRECTORY_LISTING_WEBSITES: "Directory & Listing Websites",
    EDUCATIONAL_WEBSITES: "Educational Websites",
    COMMUNITY_FORUM_WEBSITES: "Community & Forum Websites",
});

export const TECHNOLOGIES = Object.freeze({
    WORDPRESS: "WordPress",
    NEXT_JS: "Next.js",
    REACT_NATIVE: "React Native",
    NODE_JS: "Node.js",
    PYTHON: "Python",
    REACT_JS: "React.js",
    FLUTTER: "Flutter",
    LARAVEL: "Laravel",
    ".NET": ".NET",
    RUBY_ON_RAILS: "Ruby on Rails",
    OTHER: "Other (Please Specify)",
});

// FUNCTIONAS
export function GenerateServiceID(serviceType, projectType, serviceTopic, businessStage) {
    const servicePrefix = serviceType.charAt(0).toUpperCase(); // First character of SERVICE_TYPE
    const projectPrefix = projectType.charAt(0).toUpperCase(); // First character of PROJECT_TYPE
    const topicPrefix = serviceTopic.charAt(0).toUpperCase(); // First character of SERVICE_TOPIC
    const businessStageNumber = {
        [BUSINESS_STAGE.STARTUPS]: 1,
        [BUSINESS_STAGE.SMALL_BUSINESSES]: 2,
        [BUSINESS_STAGE.ESTABLISHED_ENTERPRISES]: 3
    }[businessStage];
    return `#SG-${servicePrefix}${projectPrefix}${topicPrefix}-${businessStageNumber}`;
}
export const PriceFormated = (price, currencyCode = DEFAULTCURRENCY.NAME, locale = null) => {
  const currency = CURRENCIES[currencyCode] || CURRENCIES.USD;
  const formatLocale = locale || currency.LOCALE;
  const FormattedPrice = new Intl.NumberFormat(formatLocale, {
    style: 'currency',
    currency: currency.CURRENCY_CODE,
  }).format(price);
  return FormattedPrice;
}