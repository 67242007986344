import React, {useState} from "react";
import * as Unicons from '@iconscout/react-unicons'
import { Link } from "react-router-dom";
import {sendEmail} from '../lib/EmailAPI'

export default function GetInTouch(){
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        comments: ''
    });
    const [responseMsg, setresponseMsg]=useState("");
    const [isloading, setisloading]=useState(false);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setisloading(true);
        try {
            await sendEmail(formData);
            setresponseMsg("Email sent successfully!");
            setFormData({ name: '', email: '', subject: '', comments: '' });
            setisloading(false);
        } catch (error) {
            setresponseMsg("Failed to send email. Please try again or contact at info@sackragroups.com");
            setisloading(false);
        }
    };
    return(
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
        <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Get In Touch !</h3>

                <p className="text-slate-400 max-w-xl mx-auto text-[15px]">Let’s bring your vision to life—reach out today, and let’s start building something exceptional together!</p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-[30px]">
                <div className="lg:col-span-8">
                    <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                        <form onSubmit={handleSubmit}>
                            <div className="grid lg:grid-cols-12 lg:gap-5">
                                <div className="lg:col-span-6 mb-5">
                                    <input name="name" id="name" type="text" value={formData.name} onChange={handleChange} className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]" placeholder="Name :" required={true} />
                                </div>

                                <div className="lg:col-span-6 mb-5">
                                    <input name="email" id="email" type="email" value={formData.email} onChange={handleChange} className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]" placeholder="Email :" required={true}/>
                                </div>
                            </div>

                            <div className="grid grid-cols-1">
                                <div className="mb-5">
                                    <input name="subject" id="subject" value={formData.subject} onChange={handleChange} className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]" placeholder="Subject :" required={true}/>
                                </div>

                                <div className="mb-5">
                                    <textarea name="comments" id="comments" value={formData.comments} onChange={handleChange} className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-28 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]" placeholder="Message :" required={true}></textarea>
                                </div>
                            </div>
                            <button type="submit" id="submit" name="send" className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white rounded-md h-11 justify-center flex items-center" disabled={isloading}>Send Message</button>
                            {isloading && <p className="text-amber-500 py-2">Sending....</p>}
                            {!isloading && responseMsg && <p className="text-amber-500 py-2">{responseMsg}</p>}
                        </form>
                    </div>
                </div>

                <div className="lg:col-span-4">
                    <div className="lg:ms-8">
                        <div className="flex">
                            <div className="icons text-center mx-auto">
                                <Unicons.UilPhone className=" block rounded text-2xl dark:text-white mb-0"/>
                            </div>

                            <div className="flex-1 ms-6">
                                <h5 className="text-[17px] dark:text-white mb-2 font-medium">Phone</h5>
                                <Link to="tel:+923020008853" className="text-slate-400 text-[15px]">+92 302 0008853</Link> <br />
                                <Link to="tel:+152534-468-854" className="text-slate-400 text-[15px]">+92 347 6094006</Link>
                            </div>
                        </div>

                        <div className="flex mt-4">
                            <div className="icons text-center mx-auto">
                                <Unicons.UilEnvelope className=" block rounded text-2xl dark:text-white mb-0"/>
                            </div>

                            <div className="flex-1 ms-6">
                                <h5 className="text-[17px] dark:text-white mb-2 font-medium">Email</h5>
                                <Link to="mailto:contact@example.com" className="text-slate-400 text-[15px]">info@sackragroups.com</Link>
                            </div>
                        </div>

                        <div className="flex mt-4">
                            <div className="icons text-center mx-auto">
                                <Unicons.UilMapMarker className=" block rounded text-2xl dark:text-white mb-0"/>
                            </div>

                            <div className="flex-1 ms-6">
                                <h5 className="text-[17px] dark:text-white mb-2 font-medium">Location</h5>
                                <p className="text-slate-400 text-[15px] mb-2">Sackra Groups Building, Daska, Punjab, Pakistan</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}