import React,{useEffect} from "react";
import Navbar from "../components/Navbar";
// import CTABanner from "../components/CTABanner";
// import Experience from "../components/Experience";
// import Projects from "../components/Projects";
// import Blogs from "../components/Blogs";
import GetInTouch from "../components/GetInTouch";
import Footer from "../components/Footer";
import Clients from "../components/Clients";
import AboutUsShop from "../components/AboutUsShop";
import ServiceShop from "../components/ServiceShop";

export default function IndexTwo({handleClick}){
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
      }, []);
    return(
        <>
            <Navbar/>
            {/* <HeroTwo/> */}
            <AboutUsShop/>
            <ServiceShop handleClick={handleClick}/>
            {/* <CTABanner/> */}
            {/* <Experience/> */}
            {/* <Projects/> */}
            <Clients/>  
            {/* <Blogs/> */}
            <GetInTouch/>
            <Footer/>
            
        </>
    )
}