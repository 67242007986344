import React,{useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutUsShop from "../components/AboutUsShop";
export default function NotFound(){
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
      }, []);
    return(
        <>
            <Navbar/>
            <AboutUsShop/>
            <div>
                NOT FOUND
            </div>
            <Footer/>
        </>
    )
}